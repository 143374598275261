import { createSlice } from "@reduxjs/toolkit";

import moment from "moment-timezone";
import { FiltersSliceStateType } from "../Interfaces/Redux/sliceStateTypes";
import {
    getCampaignsInitialState,
    getCustomersInitialState,
    getDevicesInitialState,
    getFailedNotificationsInitialState,
    getOrdersInitialState,
    getOrdersMotoInitialState,
} from "./utils/filtersInitialStateGenerator";

const initialState: FiltersSliceStateType = {
    orders: getOrdersInitialState(),
    customers: getCustomersInitialState(),
    campaigns: getCampaignsInitialState(),
    ordersMoto: getOrdersMotoInitialState(),
    failedNotifications: getFailedNotificationsInitialState(),
    devices: getDevicesInitialState(),
};

const formatDate = (date: moment.Moment, timezone: string) => {
    const adjustedDate = moment(date.format("YYYY-MM-DDTHH:mm:ss")).tz(
        timezone,
    );
    const formattedDate = adjustedDate.format("YYYY-MM-DDTHH:mm:ssZ");
    return formattedDate;
};

export const FiltersSlice = createSlice({
    name: "filters",
    initialState,
    reducers: {
        setFiltersDatesTimezone: (state, action) => {
            Object.keys(state).map((entity) => {
                const entityState =
                    state[entity as keyof FiltersSliceStateType];
                let date: moment.Moment;

                if ("startingDate" in entityState) {
                    date = moment.parseZone(entityState["startingDate"]);
                    entityState["startingDate"] = formatDate(
                        date,
                        action.payload,
                    );
                }
                if ("endingDate" in entityState) {
                    date = moment.parseZone(entityState["endingDate"]);
                    entityState["endingDate"] = formatDate(
                        date,
                        action.payload,
                    );
                }
                if ("createdAtStart" in entityState) {
                    date = moment.parseZone(entityState["createdAtStart"]);
                    entityState["createdAtStart"] = formatDate(
                        date,
                        action.payload,
                    );
                }
                if ("createdAtEnd" in entityState) {
                    date = moment.parseZone(entityState["createdAtEnd"]);
                    entityState["createdAtEnd"] = formatDate(
                        date,
                        action.payload,
                    );
                }
            });
        },
        setOrdersFilters: (state, action) => {
            state.orders = action.payload;
        },
        clearOrdersFilters: (state) => {
            state.orders = getOrdersInitialState();
        },
        setCustomersFilters: (state, action) => {
            state.customers = action.payload;
        },
        clearCustomersFilters: (state) => {
            state.customers = getCustomersInitialState();
        },

        setCampaignsFilters: (state, action) => {
            state.campaigns = action.payload;
        },
        clearCampaignsFilters: (state) => {
            state.campaigns = getCampaignsInitialState();
        },
        setOrdersMotoFilters: (state, action) => {
            state.ordersMoto = action.payload;
        },
        clearOrdersMotoFilters: (state) => {
            state.ordersMoto = getOrdersMotoInitialState();
        },
        setFailedNotificationsFilters: (state, action) => {
            state.failedNotifications = action.payload;
        },
        clearFailedNotificationsFilters: (state) => {
            state.failedNotifications = getFailedNotificationsInitialState();
        },
        setDevicesFilters: (state, action) => {
            state.devices = action.payload;
        },
        clearDevicesFilters: (state) => {
            state.devices = getDevicesInitialState();
        },
    },
});
export const {
    setFiltersDatesTimezone,
    setOrdersFilters,
    clearOrdersFilters,
    setCustomersFilters,
    clearCustomersFilters,
    setCampaignsFilters,
    clearCampaignsFilters,
    setOrdersMotoFilters,
    clearOrdersMotoFilters,
    setFailedNotificationsFilters,
    clearFailedNotificationsFilters,
    setDevicesFilters,
    clearDevicesFilters,
} = FiltersSlice.actions;
export default FiltersSlice.reducer;
