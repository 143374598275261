import { createSlice } from "@reduxjs/toolkit";
import { UISliceStateType } from "../Interfaces/Redux/sliceStateTypes";

const initialState: UISliceStateType = {
    isSidebarOpen: true,
    ordersOptionalFilters: [],
};

export const UISlice = createSlice({
    name: "UI",
    initialState,
    reducers: {
        toggleSidebar: (state) => {
            state.isSidebarOpen = !state.isSidebarOpen;
        },
        setOrdersOptionalFilters: (state, action) => {
            state.ordersOptionalFilters = [...action.payload];
        },
        addOrdersOptionalFilter: (state, action) => {
            state.ordersOptionalFilters = [
                ...state.ordersOptionalFilters,
                action.payload,
            ];
        },
        removeOrdersOptionalFilter: (state, action) => {
            const newFilters = state.ordersOptionalFilters.filter(
                (el) => el.filterKey !== action.payload,
            );

            state.ordersOptionalFilters = [...newFilters];
        },
    },
});
export const {
    toggleSidebar,
    setOrdersOptionalFilters,
    addOrdersOptionalFilter,
    removeOrdersOptionalFilter,
} = UISlice.actions;
export default UISlice.reducer;
