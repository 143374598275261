import { CustomTheme, Typography } from "@mui/material";
import { ThemeProvider } from "@mui/system";
import "moment-timezone";
import moment from "moment-timezone";
import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Provider } from "react-redux";
import { persistStore } from "redux-persist";
import { PersistGate } from "redux-persist/integration/react";
import "shepherd.js/dist/css/shepherd.css";
import "./App.scss";

import * as Sentry from "@sentry/react";
import {
    MIXPANEL_TOKEN,
    REPLAYS_ON_ERROR_SAMPLE_RATE,
    REPLAYS_SESSION_SAMPLE_RATE,
    SENTRY_DSN,
    SENTRY_TRACES_SAMPLE_RATE,
} from "Env";
import mixpanel from "mixpanel-browser";
import { validateUserFieldFromLocalStorage } from "utils/validateUserFieldFromLocalStorage";
import store from "./store";
import { getMerchantTheme } from "./Theme/utils/getMerchantTheme";

const AppLayout = React.lazy(() => import("./AppLayout/AppLayout"));
const persistor = persistStore(store);

validateUserFieldFromLocalStorage("role");

mixpanel.init(MIXPANEL_TOKEN, {
    debug: false,
});

if ("" !== SENTRY_DSN && process.env.NODE_ENV === "production") {
    Sentry.init({
        dsn: `${SENTRY_DSN}`,
        integrations: [
            Sentry.browserTracingIntegration(),
            Sentry.replayIntegration(),
        ],
        // Tracing
        tracesSampleRate: SENTRY_TRACES_SAMPLE_RATE, // Rate at which transactions are captured for performance analysis
        tracePropagationTargets: [], // TODO Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
        // Session Replay
        replaysSessionSampleRate: REPLAYS_SESSION_SAMPLE_RATE, // Rate at which replays are captured for successful sessions
        replaysOnErrorSampleRate: REPLAYS_ON_ERROR_SAMPLE_RATE, // Rate at which replays are captured when an error occurs
    });
}

function App(): JSX.Element {
    const theme = getMerchantTheme() as CustomTheme;
    const { t } = useTranslation();
    moment.updateLocale("en", {
        relativeTime: {
            future: `${t("RELATIVE_TIME.FUTURE", { num: "%s" })} `,
            past: `${t("RELATIVE_TIME.PAST", { num: "%s" })}`,
            s: `${t("RELATIVE_TIME.SECOND", { num: "1" })}`,
            ss: `${t("RELATIVE_TIME.SECOND", { num: "%s" })}`,
            m: `${t("RELATIVE_TIME.MINUTE", { num: "1" })}`,
            mm: `${t("RELATIVE_TIME.MINUTE", { num: "%d" })}`,
            h: `${t("RELATIVE_TIME.HOUR", { num: "1" })}`,
            hh: `${t("RELATIVE_TIME.HOUR", { num: "%d" })}`,
            d: `${t("RELATIVE_TIME.DAY", { num: "1" })}`,
            dd: `${t("RELATIVE_TIME.DAY", { num: "%d" })}`,
            M: `${t("RELATIVE_TIME.MONTH", { num: "1" })}`,
            MM: `${t("RELATIVE_TIME.MONTH", { num: "%d" })}`,
            y: `${t("RELATIVE_TIME.YEAR", { num: "1" })}`,
            yy: `${t("RELATIVE_TIME.YEAR", { num: "%d" })}`,
        },
    });
    useEffect(() => {
        document.title = theme.custom.headName;
    }, []);
    return (
        <Provider store={store}>
            <PersistGate loading={null} persistor={persistor}>
                <ThemeProvider theme={theme}>
                    <div className="App">
                        <React.Suspense
                            fallback={<Typography>{t("LOADING")}</Typography>}
                        >
                            {/* <ShepherdJourneyProvider> */}
                            {/* <ShepherdTour
                                steps={mainTour as ShepherdOptionsWithType[]}
                                tourOptions={tourOptions}
                            > */}
                            <AppLayout />
                            {/* </ShepherdTour> */}
                            {/* </ShepherdJourneyProvider> */}
                        </React.Suspense>
                    </div>
                </ThemeProvider>
            </PersistGate>
        </Provider>
    );
}

export default App;
