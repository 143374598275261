import { createSlice, PayloadAction } from "@reduxjs/toolkit";

interface TablePreferenceState {
    hiddenColumns: string[];
    rowsPerPage: number;
    sortBy: { desc: boolean; id: string }[];
    columnsOrder: string[];
}

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
const createTablePreferenceSlice = (
    tableName: string,
    initialState: TablePreferenceState,
) => {
    const tablePreferenceSlice = createSlice({
        name: `${tableName}TablePreference`,
        initialState,
        reducers: {
            setHiddenColumns: (state, action: PayloadAction<string[]>) => {
                state.hiddenColumns = action.payload;
            },
            setRowsPerPage: (state, action: PayloadAction<number>) => {
                state.rowsPerPage = action.payload;
            },
            setSortBy: (
                state,
                action: PayloadAction<{ desc: boolean; id: string }[]>,
            ) => {
                state.sortBy = action.payload;
            },
            setColumnOrder: (state, action: PayloadAction<string[]>) => {
                state.columnsOrder = action.payload;
            },
        },
    });

    return {
        reducer: tablePreferenceSlice.reducer,
        actions: tablePreferenceSlice.actions,
    };
};

export default createTablePreferenceSlice;
