import ClearLocalStorage from "@paylands-frontend/libraries/dist/ClearLocalStorage";

export const validateUserFieldFromLocalStorage = (field: string) => {
    try {
        const user = localStorage.getItem("user");
        if (user) {
            const parsedUser = JSON.parse(user);
            if (!parsedUser?.[field]) {
                throw new Error(`Missing ${field} field`);
            }
        }
    } catch {
        new ClearLocalStorage([
            "i18nextLng",
            "settings",
            "persist:root",
        ]).clear();
        window.location.reload();
    }
};
