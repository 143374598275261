import React from "react";
import { RolesEnum } from "../../Interfaces/User/dataTypes";
import store from "../../store";

export type AccessControlProps = {
    allowedPermissions?: string[];
    allowedRoles?: RolesEnum[];
    permissive?: boolean;
    children?: React.ReactNode;
    renderNoAccess?: () => JSX.Element;
};
/**
 * A function that checks if user has any of provided permissions
 * @param  {string[]} allowedPermissions  - A list of permissions user has to have in order to view the component.
 * @param {RolesEnum[] | undefined} allowedRoles - A list of roles allowed to view the component.
 * @param {boolean | undefined} permissive - Determines if permissions check is strict or not.
 * @returns {boolean} - A boolean that specifics if user has any of provided permissions.
 */
export const checkPermissions = (
    allowedPermissions: string[],
    allowedRoles?: RolesEnum[],
    permissive: boolean = false,
): boolean => {
    const auth = store.getState().auth;
    const userPermissions: string[] = auth.scope || [""];
    const userRole: RolesEnum | null =
        (auth.user?.role.toLowerCase() as RolesEnum) || null;

    if (allowedPermissions.length === 0 || allowedPermissions[0] === "") {
        if (!allowedRoles || allowedRoles.length === 0) {
            return true;
        }
        return allowedRoles.includes(userRole);
    }

    const roleIsAllowed =
        !allowedRoles ||
        allowedRoles.length === 0 ||
        (userRole && allowedRoles.includes(userRole));

    if (!roleIsAllowed) {
        return false;
    }

    if (permissive) {
        return userPermissions.some((permission: string) =>
            allowedPermissions.includes(permission),
        );
    }
    return allowedPermissions.every((permission) =>
        userPermissions.includes(permission),
    );
};

/**
 * Wrapping component that adds access control check. If user access controll list includes any of provided to the component alowed permission
 * it will render the child element, other way nothing/ component provided as renderNoAccess will be rendered.
 *
 * @param {string[]} props.allowedPermissions - A list of permissions user has to have in order to view the component.
 * @param {RolesEnum[]} props.allowedRoles - A list of roles allowed to view the component.
 * @param {boolean | undefined} permissive - Determines if permissions check is strict or not.
 * @param {() => JSX.Element} props.renderNoAccess - A component to be rendered if user has no permissions. If not provided - nothing will be rendered.
 * @param {React.ReactNode} props.children - A component to be rendered if user has permissions to view it.
 * @returns
 */
const AccessControl = ({
    allowedPermissions = [],
    allowedRoles,
    permissive,
    children,
    renderNoAccess,
}: AccessControlProps): JSX.Element | null => {
    const permitted = checkPermissions(
        allowedPermissions,
        allowedRoles,
        permissive,
    );
    if (permitted) {
        return <>{children}</>;
    }
    if (renderNoAccess) return renderNoAccess();
    else return null;
};

export default AccessControl;
