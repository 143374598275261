import { Theme, ThemeOptions, createTheme } from "@mui/material/styles";
import { createCustomThemeOptions } from "./createThemeOptions";
import { PayshopOptions } from "../Options/Payshop";
import PaynoPainOptions from "../Options/PaynoPainStandard";
import { getMerchantSubdomain } from "./getMerchantSubdomain";
import PaynoPainStandard from "../Options/PaynoPainStandard";
import { deepmerge } from "@mui/utils";

const getMerchantThemeOptions = (subdomain: string): ThemeOptions => {
    switch (subdomain) {
        case "popbackoffice.payshop.pt":
            return createCustomThemeOptions(PayshopOptions);
        case "paynopain":
        default:
            return PaynoPainOptions;
    }
};

export const getMerchantTheme = (): Theme => {
    const merchantSubdomain = getMerchantSubdomain();
    return createTheme(
        deepmerge(
            PaynoPainStandard,
            getMerchantThemeOptions(merchantSubdomain),
        ),
    );
};
