import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { ClientDataGet } from "@paylands-frontend/api/dist/ClientData";
import { Api } from "../utils/Api";
import { MerchantSliceStateType } from "../Interfaces/Redux/sliceStateTypes";
import { checkPermissions } from "../Components/AccessControl/AccessControl";

const initialState: MerchantSliceStateType = {
    data: null,
    loading: false,
    errors: null,
};

export const getMerchant = createAsyncThunk(
    "merchant/get",
    async (uuid: string, { rejectWithValue }) => {
        if (checkPermissions(["client.get"])) {
            const response = await new ClientDataGet(Api)
                .fetch(uuid)
                .catch((error) => rejectWithValue(error.response.data));
            return response.data.client;
        } else {
            rejectWithValue(403);
        }
    },
);

export const merchantsSlice = createSlice({
    name: "merchant",
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(getMerchant.pending, (state) => {
                state.loading = true;
            })
            .addCase(getMerchant.fulfilled, (state, action) => {
                state.loading = false;
                state.data = action.payload;
            })
            .addCase(getMerchant.rejected, (state, action) => {
                state.loading = false;
                state.data = null;
                state.errors = action.payload;
            });
    },
});

export default merchantsSlice.reducer;
