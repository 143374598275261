function getEnvString(name: string): string {
    const value = process.env[name];
    if (value === undefined) {
        throw new Error(`Environment variable ${name} is not defined`);
    }
    return value;
}

function getEnvFloat(name: string): number {
    const value = getEnvString(name);
    const parsed = parseFloat(value);
    if (isNaN(parsed)) {
        throw new Error(`Environment variable ${name} is not a valid float`);
    }
    return parsed;
}

// Export environment variables
export const SERVER_URL = getEnvString("REACT_APP_SERVER_URL");
export const WEB_SERVICE_URL = getEnvString("REACT_APP_WEB_SERVICE_URL");
export const DOMAIN = getEnvString("REACT_APP_DOMAIN");
export const MIXPANEL_TOKEN = getEnvString("REACT_APP_MIXPANEL_TOKEN");
export const SENTRY_DSN = getEnvString("REACT_APP_SENTRY_DSN");
export const SENTRY_TRACES_SAMPLE_RATE = getEnvFloat(
    "REACT_APP_SENTRY_TRACES_SAMPLE_RATE",
);
export const REPLAYS_SESSION_SAMPLE_RATE = getEnvFloat(
    "REACT_APP_REPLAYS_SESSION_SAMPLE_RATE",
);
export const REPLAYS_ON_ERROR_SAMPLE_RATE = getEnvFloat(
    "REACT_APP_REPLAYS_ON_ERROR_SAMPLE_RATE",
);
export const DEBT_COLLECTOR_SANDBOX_URL = getEnvString(
    "REACT_APP_DEBT_COLLECTOR_SANDBOX_URL",
);
export const DEBT_COLLECTOR_SANDBOX_API_KEY = getEnvString(
    "REACT_APP_DEBT_COLLECTOR_SANDBOX_API_KEY",
);
export const DEBT_COLLECTOR_PRODUCTION_URL = getEnvString(
    "REACT_APP_DEBT_COLLECTOR_PRODUCTION_URL",
);
export const DEBT_COLLECTOR_PRODUCTION_API_KEY = getEnvString(
    "REACT_APP_DEBT_COLLECTOR_PRODUCTION_API_KEY",
);
